import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Product } from '../models/bill-payments/products/product';
import { IPage } from '../models/common/i-page';
@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  private baseUrl: string = environment.urls.api + 'product';

  public products = new BehaviorSubject<Product[]>([]);

  constructor(private http: HttpClient) {}

  public getProductList(isActive: boolean, pageSize: number, pageId: number) {
    console.log('get products');

    var params: HttpParams = new HttpParams()
      .set('isActive', isActive)
      .set('pageSize', pageSize)
      .set('pageId', pageId);

    return this.http
      .get<IPage>(`${this.baseUrl}/list`, { params: params })
      .pipe(
        map((result) => {
          console.log('Products', result);

          this.products.next(result.content);
          return result;
        })
      );
  }
}
