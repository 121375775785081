import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { LoaderService } from '../../services/loader.service';
import { BillPaymentsService } from '../../services/bill-payments.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input('title') title!: string;
  @Input() id!: string;
  @Input('size') size!: string;
  @Input('minimizeButton') minimizeButton: boolean = false;
  @Input('closeButton') closeButton: boolean = true;

  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef, private loader: LoaderService, private billPaymentsService: BillPaymentsService) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.loader.showLoader();
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    this.initialiseModal();
  }
  @HostListener('click', ['$event'])
  stopEventProp(event: any) {
    event.stopPropagation();
    // console.log(event);
  }

  initialiseModal() {
    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    // this.modalService.add(this);

    this.loader.hideLoader();
  }

  openModal() {
    // console.log(document.body.contains(this.element));
    if (!document.body.contains(this.element)) {
      //   console.log('appended');
      document.body.appendChild(this.element);
    }
    const element = this.element?.querySelector('#' + this.id);

    element.classList.add('modalDialog-open');
    element.classList.remove('modalDialog-minimized');
  }
  closeModal() {
    const element = this.element;
    element?.classList.remove('modalDialog-open');
    element?.remove();
    // element?.classList.add('modalDialog-open');
    this.billPaymentsService.resetForms.next(true);
  }
  dismissModal() {
    document.getElementById(this.id)?.classList.remove('modalDialog-open');
  }
  minimizeModal() {
    const element = this.element?.querySelector('#' + this.id);
    element?.classList.add('modalDialog-minimized');
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.loader.hideLoader();
    this.element.remove();
  }
}
