import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CreateInvoice } from '../models/bill-payments/invoices/create-invoice';
import { InvStatus, InvoiceStatus } from '../models/bill-payments/invoices/invoice-status';
import { InvoiceCreated } from '../models/bill-payments/invoices/invoice-created';
import { Invoice } from '../models/bill-payments/invoices/invoice';
@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  private baseUrl: string = environment.urls.api + 'invoice';

  public invoiceStatus = new BehaviorSubject<InvStatus | null>(null);

  public invoice = new BehaviorSubject<Invoice | null>(null);

  constructor(private http: HttpClient) { }

  public createInvoice (invoice: CreateInvoice) {
    return this.http.post<InvoiceCreated>(`${this.baseUrl}/create`, invoice);
  }

  public updateInvoiceStatus(invoiceNumber: string) {

    /* var params: HttpParams = new HttpParams()
			.set('invoiceNumber', invoiceNumber); */

    return this.http.get<InvStatus>(`${this.baseUrl}/status/update/${invoiceNumber}`)
    .pipe(
      map((result) => {
        this.invoiceStatus.next(result);
        return result;
      })
    );
  }

  public getInvoice(invoiceNumber: string, accountNumber: string) {

    /* var params: HttpParams = new HttpParams()
    .set('invoiceNumber', invoiceNumber)
    .set('accountNumber', accountNumber); */

    return this.http.get<Invoice>(`${this.baseUrl}/search/${invoiceNumber}/${accountNumber}`)
    .pipe(
      map((result) => {
        this.invoice.next(result);
        return result;
      })
    );
  }

}
