<div class="w-100 float-left header-and-banner-con banner-overlay-img">
  <div class="container">
    <div class="overlay-img">
      <!-- navbar-start -->
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/"><img src="assets/static/image/Prepaid24-Logo-White.png" alt="Prepaid24"
            class="img-fluid"></a>
        <button class="navbar-toggler p-0 collapsed" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mr-auto">
            <li class="nav-item active ">
              <a class="nav-link p-0 text-white" routerLink="/">Pay Bills</a>
            </li>
            <li class="nav-item">
              <a class="nav-link p-0 text-white" routerLink="/about-us">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link p-0 text-white" routerLink="/privacy-policy">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link p-0 text-white" routerLink="/terms">Terms &amp; Conditions</a>
            </li>
          </ul>
          <a href="tel:+263777007007" class="navbar-btn text-white">
            <i class="fas fa-phone-volume"></i>
            +263 777 007 007
          </a>
        </div>
      </nav>
      <!-- navbar-end -->
      <!-- banner-start -->
      <section>
        <div class="w-100 float-left generic-banner-con text-xl-left text-lg-left text-center">
          <div class="container">
            <div class="generic-banner-content text-white text-center">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </section>
      <!-- banner-end -->
    </div>
  </div>
</div>
<!-- header-and-banner-section -->
<!-- quality-system-section -->
<section>
  <div class="w-100 float-left quality-system-con Experince-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex align-items-center">
          <div class="quality-system-left-con">
            <h2>Simplifying Transactions
            </h2>
            <p>Prepaid24.co.zw is a online bill payment and voucher platform dedicated to simplify transactions for
              local Zimbabweans and individuals in the diaspora. With a comprehensive suite of services, Prepaid24.co.zw
              has emerged as the go-to-solution for convenient and secure payments for municipality bills, city council
              rates, airtime vouchers, account payments, insurance premiums, and subscriptions.</p>
            <p>Our vision is to become the preeminent online bill payments platform in Zimbabwe, while our mission is to
              provide unparalleled online payment solutions that enhance the lives of our customers.</p>

          </div>
        </div>
        <div class="col-lg-6 col-md-6 d-md-flex align-items-md-end">
          <div class="quality-system-right-con position-relative text-center overlay-img">
            <figure class="mb-0 left-curve-img wow slideInLeft">

            </figure>
            <figure class="mb-0">
              <img src="assets/static/image/Who-We-Are-Prepaid24.jpg" alt="Who We Are" class="img-fluid human-img">
            </figure>
            <figure class="mb-0 right-curve-img wow slideInRight">

            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- quality-system-section -->
<!-- core-vaule-section -->
<section>
  <div class="w-100 float-left core-vaule-con">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-md-flex align-items-md-end order-xl-0 order-lg-0 order-md-0 order-2">
          <div class="form-left-con core-vaule-left-con position-relative text-center">
            <figure class="mb-0 left-curve-img wow slideInLeft"
              style="visibility: visible; animation-name: slideInLeft;">

            </figure>
            <figure class="mb-0">
              <img src="assets/static/image/Prepaid24-Zimbabwe.png" alt="Prepaid24 Zimbabwe"
                class="mb-0 img-fluid human-img">
            </figure>
            <figure class="mb-0 right-curve-img wow slideInRight"
              style="visibility: visible; animation-name: slideInRight;">

            </figure>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="core-vaule-right-con text-xl-left text-lg-left text-md-left text-center">
            <div class="core-vaule-title">
              <h2>Fostering financial inclusivity and connectivity for all </h2>
              <p>Our primary focus is on serving the needs of both local Zimbabweans and those in the diaspora.</p>
            </div>
            <div class="core-vaule-box-con">
              <div class="core-vaule-box-item position-relative">
                <figure class="mb-0">

                </figure>
                <div class="core-vaule-box-title">
                  <h5>Our Vision</h5>
                  <span class="d-block">At Prepaid24.co.zw, we envision becoming the ultimate destination for online
                    bill payments in Zimbabwe. We aspire to be the trusted name that Zimbabweans turn to when they need
                    to manage their financial responsibilities swiftly and securely. We will achieve this vision by
                    continually improving our services, expanding our offerings and exceeding customer
                    expectations.</span>
                </div>
              </div>
              <div class="core-vaule-box-item position-relative">
                <figure class="mb-0">

                </figure>
                <div class="core-vaule-box-title">
                  <h5>Our Mission</h5>
                  <span class="d-block">Our Mission is to provide second-to-none online payment solutions that empower
                    our customers. We are dedicated to delivering exceptional convenience, security and efficiency in
                    all our services. We are committed to staying in the forefront of technological advancements,
                    ensuring that our customers have access to the latest innovations in online bill payments.</span>
                </div>
              </div>
              <div class="core-vaule-box-item position-relative mb-0">
                <figure class="mb-0">

                </figure>
                <div class="core-vaule-box-title">
                  <h5></h5>
                  <span class="d-block"></span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<!-- core-vaule-section -->

<!-- logo-section -->
<div class="w-100 float-left logo-con">
  <div class="container">
    <div class="logo-inner-con">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- logo-section -->
<!-- weight-footer-section -->
<section>
  <div class="w-100 float-left weight-footer-con">
    <div class="container">
      <div class="weight-footer-inner-con">
        <div class="row">
          <div class="col-lg-5 col-12 text-xl-left text-lg-left text-center">
            <div class="weight-footer-content ">
              <figure>
                <img src="assets/static/image/Prepaid24-Logo.png" alt="Prepaid24 Zimbabwe" class="img-fluid">
              </figure>
              <p class="col-lg-11 col-md-7 pl-0 pr-0 ml-lg-0 mr-lg-0 ml-md-auto mr-md-auto">Online Bill Payments, Buy
                ZESA tokens, Pay School & University fees, Book bus tickets, Get Airtime Top-up, Buy lotto vouchers, and
                so much more.</p>
              <div class="weight-social-list">
                <ul class="list-unstyled mb-0">
                  <li class="d-inline-block"><a href="https://www.facebook.com/"><i
                        class="fab fa-facebook-square d-flex align-items-center justify-content-center text-white pr-0"></i></a>
                  </li>
                  <li class="d-inline-block"><a href="https://twitter.com/?lang=en"><i
                        class="fab fa-twitter-square d-flex align-items-center justify-content-center text-white pr-0"></i></a>
                  </li>
                  <li class="d-inline-block"><a href="https://www.linkedin.com/signup"><i
                        class="fab fa-linkedin d-flex align-items-center justify-content-center text-white pr-0"></i></a>
                  </li>
                  <li class="d-inline-block"><a href="https://www.pinterest.com/"><i
                        class="fab fa-pinterest-square mr-0 d-flex align-items-center justify-content-center text-white pr-0"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-4 col-6">
            <div class="weight-footer-content">
              <h5>Important Link</h5>
              <ul class="list-unstyled mb-0">
                <li><i class="fas fa-caret-right"></i><a routerLink="/about-us">About Us</a></li>
                <li><i class="fas fa-caret-right"></i><a routerLink="/terms">Terms &amp; Conditions</a></li>
                <li><i class="fas fa-caret-right"></i><a routerLink="/privacy-policy">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-3 col-6">
            <div class="weight-footer-content">
              <h5>Support</h5>
              <ul class="list-unstyled mb-0">
                <li><i class="fas fa-caret-right"></i>Support</li>
                <li><i class="fas fa-caret-right"></i>Contact Us</li>
                <li><i class="fas fa-caret-right"></i>Faq</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-5 col-sm-5 col-12">
            <div class="weight-footer-content">
              <h5>Get in Touch</h5>
              <ul class="list-unstyled mb-0">
                <li><span>Email: </span>info&#64;prepaid24.co.zw</li>
                <li><span>Phone: </span>+263 777 007 007</li>
                <li><span>WhatsApp: </span>+263 777 007 007</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- weight-footer-section -->
<!-- footer-section -->
<div class="w-100 float-left footer-con text-xl-left text-lg-left text-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <p class="mb-0">Copyright ©2024 Prepaid24.co.zw All Rights Reserved</p>
      </div>
      <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center">
        <figure class="mb-0 d-inline-block">
          <img src="assets/static/image/payment-img1.png" alt="payment-img" class="img-fluid">
        </figure>
        <figure class="mb-0 d-inline-block">
          <img src="assets/static/image/payment-img2.png" alt="payment-img" class="img-fluid">
        </figure>
        <figure class="mb-0 d-inline-block">
          <img src="assets/static/image/payment-img3.png" alt="payment-img" class="img-fluid">
        </figure>
        <figure class="mb-0 d-inline-block mr-0">
          <img src="assets/static/image/payment-img4.png" alt="payment-img" class="img-fluid">
        </figure>
      </div>
    </div>
  </div>
</div>
