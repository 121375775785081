<!-- <app-spinner></app-spinner>
 -->

<ng-container *ngIf="products.length > 0 && !isLoading; else Error">
  <div class="product-list">
    <div class="container">
      <div class="row bg-white">
        <div class="col-lg-4 col-md-4 col-sm-6 pl-0 pr-0" *ngFor="let product of products">

          <div class="product-detail">
            <img [src]="product.productImage" />
            <h4><strong class="product-name">{{ product.productName }}</strong></h4>
            <span class="product-name">{{ product.productDescription }}</span>
            <!-- <ng-container *ngIf="!product.productPackage">
            <span class="product-name">{{ product.currencyName }} {{ product.price / 100 | number: '1.2-2' }}</span>
          </ng-container> -->
            <button mat-fab extended color="primary" (click)="buyProduct(product)">
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #Error>
  <div class="container">
    <div class="row bg-white">
    <div style="width: 100%; padding: 80px 70px; text-align: center; background-color: #fff;">
    <p>
      <img src="/assets/static/image/Prepaid24-Logo.png" >
    </p>
    <h1>{{isLoading ? 'Connecting to remote services.' : 'Error connecting to remote services. Please retry later'}}</h1>
    </div>
    </div>
  </div>
</ng-template>

<app-modal id="buyProduct" size="md" title="Make A Purchase">
  <app-bill-payment-workflow [product]="selectedProduct"></app-bill-payment-workflow>
</app-modal>
