<!-- header-and-banner-section -->
<div class="w-100 float-left header-and-banner-con banner-overlay-img">
  <div class="container">
    <div class="overlay-img">
      <!-- navbar-start -->
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/"><img src="assets/static/image/Prepaid24-Logo-White.png"
            alt="Prepaid24" class="img-fluid"></a>
        <button class="navbar-toggler p-0 collapsed" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto mr-auto">
            <li class="nav-item active ">
              <a class="nav-link p-0 text-white" routerLink="/">Pay Bills</a>
            </li>
            <li class="nav-item">
              <a class="nav-link p-0 text-white" routerLink="/about-us">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link p-0 text-white" routerLink="/privacy-policy">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link p-0 text-white" routerLink="/terms">Terms &amp; Conditions</a>
            </li>
          </ul>
          <a href="tel:+263777007007" class="navbar-btn text-white">
            <i class="fas fa-phone-volume"></i>
            +263 777 007 007
          </a>
        </div>
      </nav>
      <!-- navbar-end -->
      <!-- banner-start -->
      <section>
        <div class="w-100 float-left generic-banner-con text-xl-left text-lg-left text-center">
          <div class="container">
            <div class="generic-banner-content text-white text-center">
              <h1>Terms &amp; Conditions</h1>
            </div>
          </div>
        </div>
      </section>
      <!-- banner-end -->
    </div>
  </div>
</div>
<!-- header-and-banner-section -->
<!-- quality-system-section -->
<section>
  <div class="w-100 float-left quality-system-con Experince-section">
    <div class="container">
      <h2>Definitions:</h2>
      <p><strong>Agreement:</strong> The agreement between Prepaid24 and the User, constituted by these terms and
        conditions (including Prepaid24's Privacy Policy), in accordance with which Prepaid24 will provide the Service.
      </p>

      <p><strong>Business Tuesday or Thursday:</strong> A Tuesday or a Thursday that is not a public holiday in South
        Africa.</p>

      <p><strong>Commercially Reasonable Endeavours:</strong> means taking all such steps and performing them in such a
        way as a well-managed company would undertake to achieve a particular desired result for its own benefit
        assuming such company was acting in a determined, prudent, and reasonable manner.</p>

      <p><strong>Duplicate Recharge Attempt:</strong> A second or further attempt to recharge from the same cell phone
        number, in exactly the same amount, during the first 5 days after the initial transaction.</p>

      <p><strong>Financial Advice:</strong> Any recommendation, guidance or proposal of a financial nature furnished by
        any means or medium to a person in respect of purchasing any financial product, or in respect of the investment
        in any financial product or on the conclusion of any other transaction including a loan or cession aimed at the
        incurring of any liability, or the acquisition of any right or benefit in respect of any financial product, or
        the variation of any term or condition applying to a financial product, on the replacement of any such product,
        or on the termination of any purchase of investment in any such product, and irrespective of whether or not such
        advice is furnished in the course of or incidental to financial planning in connection with the affairs of a
        person or results in any such purchase, investment, transaction variation, replacement or termination, as the
        case may be, being affected.</p>
      <p><strong>Parties:</strong> Prepaid24 and User collectively; Party will mean either of them as the context
        requires.</p>

      <p><strong>Prepaid24:</strong> Prepaid24.co.zw, being the company that makes available to Users the online payment
        platform that provides access to online prepaid electricity, airtime, and other transactions.</p>

      <p><strong>Recharge Voucher/Token or Pin:</strong> A sequence of numbers sent by Prepaid24 to the user via an
        electronic medium. This includes an SMS by cell phone, an email, or any message via a choice of other electronic
        platforms.</p>

      <p><strong>Registration details:</strong> Email address, cell number, meter number, municipality, or other details
        that Prepaid24 might require to give effect to a transaction.</p>

      <p><strong>Service:</strong> The payment facilitation service that Prepaid24 provides under this Agreement.
        Third Party: Any other party with which Prepaid24 interfaces in order to ensure the Service is provided to the
        User. These include, but are not limited to, municipalities, private metering companies, cell phone networks,
        Internet platforms, vending companies contracted by municipalities and telephone networks.</p>

      <p><strong>Third Party Levy:</strong> Any charge, cost, levy or other amount that a Third Party may require
        Prepaid24 to pay in order to process a transaction on behalf of the User.
        User: A consumer purchasing prepaid products or services via EFT or other payment methods, using Prepaid24's
        platform.
      </p>
      <p><strong>1. Introductory</strong>
        1.1. Prepaid24 will provide the Service in accordance with the Agreement. The Agreement will not in any way
        overrule, eliminate, or adjust the terms and conditions entered into between any User and his/her bank and/or
        any service provider.</p>

      <p>1.2. The User acknowledges that Prepaid24 does not hold a deposit-taking licence (a banking license) and cannot
        hold funds on behalf of Users which are not the proceeds of payments made by them to purchase specific goods or
        products on their behalf.</p>

      <p>1.3. Prepaid24 will not be liable for any loss suffered due to incorrect information that the User supplies,
        such as (but not limited to): the incorrect cell number, electrical meter number, amount or email address.
        Prepaid24 will however use Commercially Reasonable Endeavours to recharge the correctly supplied prepaid number
        or electrical meter.</p>

      <p>1.4 The communication channel for purposes of this Agreement will be through online chat or by e-mail only.
        Prepaid24 will provide no cellular or other contact numbers.</p>

      <p><strong>2. Copyright</strong></p>
      <p>Neither Third Parties nor Users may use a Prepaid24 logo or other proprietary graphic or trademark of Prepaid24
        to link to this website without the express written permission of Prepaid24. Prepaid24 may revoke this right at
        any time without providing reasons for the revocation. Third Parties or Users are granted a limited,
        non-exclusive right to create a hyperlink to this website provided such link does not portray Prepaid24 in a
        false, misleading, derogatory, or defamatory manner.</p>

      <p><strong>3. Intellectual Property Rights</strong></p>
      <p>All the trademarks, data, and content on this website, including but not limited to software, databases, text,
        video clips, graphics, icons, hyperlinks, private information, designs and agreements, are the property of, or
        are licensed to Prepaid24. All these are protected from infringement by a third party, in terms of local and
        international legislation and treaties.</p>

      <p><strong>4. Prepaid24 service delivery</strong></p>
      <p>4.1 To combat possible fraud or corruption, our system has a barrier which might prevent Duplicate Recharge
        Attempts. If such Duplicate Recharge Attempts are successful within the 5 day period, the conditions stipulated
        in clause 4.5 will apply. Prevent inconvenience by recharging with a slightly different amount in Dollars. Do
        not try to use cents as Prepaid24's systems do not process cents.</p>
      <p>4.2 Although Prepaid24 completes most recharges in less than 15 minutes, Prepaid24 does not in any way
        guarantee a turnaround time. The main reason for this is Prepaid24's reliance on Third Parties.</p>

      <p>4.3 Transactions will only be processed once the EFT payment has been cleared by the bank.</p>

      <p>4.4 Should a recharge not have been done, and Prepaid24 is made aware of this by mean of a query logged via the
        online support function on its website, Prepaid24 will respond to all such queries quickly and efficiently.
        Although most personal communication from Prepaid24 to Users commence within an hour or two from receiving an
        enquiry, Prepaid24 reserves the right to communicate with Users personally with regards to any failures within 2
        business days from receiving a failure report. The only reason Prepaid24 reserves this right is to ensure
        communication to all Users during critical system failures of any Third Party.</p>

      <p>4.5 If the User requests a refund for any reason, such a request will be processed, unless the User has
        requested a Voucher or Pin from Prepaid24 before Prepaid24 received the refund request, and furthermore subject
        to clause 5.1. The time when Prepaid24 receives the refund request will not be the time when the User logs the
        refund request, but the time when Prepaid24 support staff become aware of such a refund request. Automatically
        programmed processes continuously run in the background of our system to attempt to process unsuccessful
        transactions. For this reason, Prepaid24 cannot be held liable if a recharge takes place in the window period
        from the User logging a refund request to the time that Prepaid24's support staff become aware of such a
        request. If that happens, Prepaid24 will not make the refund.</p>

      <p>4.6 Should a Recharge Voucher/Token or Pin not have been issued and support staff become aware of the User's
        request for a refund, such a refund will be effected on the first Business Tuesday or Thursday (whichever comes
        first) after the refund became known to support staff, provided Prepaid24 has the banking details of the
        particular User. If not, support staff will request the details. Upon receiving the information, Prepaid24 will
        process the refund by the first Business Tuesday or Thursday after the bank details were provided.</p>

      <p>4.7 When the PayFast payment gateway is used, or any EFT is made into any of Prepaid24's bank accounts to pay
        for any Prepaid24 product, the following fraud prevention steps could be implemented:</p>

      <p>4.7.1 Suspicious transaction amounts for any first-time users could cause a 24-48 business hour delay.</p>

      <p>4.7.2. Suspicious payment patterns, of any nature, can cause delays of up to 48 business hours.</p>

      <p>4.7.3 All measures that Prepaid24 implement are to combat fraud, with a view to protect Prepaid24 as well as
        other South Africans who are potential victims of fraud every day.</p>

      <p><strong>5. Fees and charges</strong></p>
      <p>5.1 Prepaid24 provides a method of obtaining prepaid airtime and electricity vouchers on behalf of Users, by
        combining existing internet banking methods and infrastructure. Prepaid24 may deduct any or all Third Party
        Levies from Users' payments for successful recharges. A recharge will, therefore, be for the amount paid by the
        User, less any such Third Party Levies. If the User claims a refund, the User must supply sufficient proof of
        repayment of the Third Party Levy to the satisfaction of both the Bank and/or Prepaid24 before the refund will
        take place.</p>

      <p>5.2 All prices are inclusive of VAT.</p>

      <p>5.3 All transactions must be done in Dollars amounts. No cent amount will be processed or taken into
        consideration in any dispute whatsoever.</p>


      <p><strong>6. User conduct and responsibility</strong></p>
      <p>6.1 Should the User commence a transaction with Prepaid24, the User acknowledges and accepts the possible delay
        caused by any of the Third Parties involved to successfully effect the transaction. Should the User supply
        incorrect Registration Details or insufficient banking detail to Prepaid24 to effect a successful transaction,
        the User will be required to supply sufficient and original proof of payment with the correct cell number or
        meter number to be charged. Such proof must be furnished with an enquiry submitted through the online support
        function on Prepaid24's website.</p>

      <p>6.2 It is the responsibility of the User to ensure that their payments to Prepaid24 are made for the correct
        amount. If a payment is made for an incorrect amount and Prepaid24 successfully processes the transaction,
        Prepaid24 will not be held liable to refund that amount, as the product has already been provided and delivered.
      </p>

      <p>6.3 It is the responsibility of the User to notify Prepaid24, through the online support function on its
        website should the User not have received his or her recharge voucher or pin within 15 minutes.</p>

      <p><strong>7. Privacy</strong></p>
      <p>7.1 The details any User provides on this website will not be given to any Third Party for any reason other
        than as required to affect a recharge or as otherwise stated by our Privacy Policy.</p>

      <p>7.2 At Prepaid24.co.zw (“The Company”) we are committed to protecting your privacy and to ensure that your
        personal information is collected and used properly, lawfully and transparently.</p>

      <p>7.3 Access to Information: Kindly find the following forms available for download:
        • REQUEST FOR ACCESS TO RECORD
        • OUTCOME OF REQUEST AND OF FEES PAYABLE
        • INTERNAL APPEAL FORM</p>

      <p>7.4 By accepting these Terms &amp; Conditions, your (Company / Private person) understand your right to privacy and
        the right to have your personal information processed in accordance with the conditions for the lawful
        processing of personal information and hereby give your consent to the Company to collect, process and
        distribute relevant personal information where the company is legally required to do so. By accepting these
        Terms &amp; Conditions, you understand and consent to the Company sharing your personal information strictly for the
        purposes hereof as set out above and in their Privacy Policy.</p>

      <p>7.5 Prepaid24 will send Users WhatsApp messages from time to time, unless a User requests to be completely
        removed from Prepaid24's WhatsApp list. These messages will include the following:- Transactional messages (for
        new registrations & purchases)- Purchase reminders (as set up by the User)- New product and/or feature
        launches- Important municipal and company updates from Prepaid24</p>

      <p><strong>8. Disclaimer and Warranty</strong></p>
      <p>8.1 Prepaid24 is merely a payment platform and does not guarantee the Recharge Voucher/Token or Pin it
        supplies. These are generated by Third Parties. These companies are ultimately responsible to ensure that such
        Recharge Voucher/Token or Pin is in working order. Should any Recharge Voucher/Token or pin not be in working
        order, Prepaid24 will use Commercially Reasonable Endeavours to assist the User to obtain a Recharge
        Voucher/Token or Pin in working order, by giving all relevant contact details of Third Parties to the User. By
        doing this, Prepaid24 does not in any way acknowledge responsibility for a "working" Recharge Voucher/Token or
        Pin but is merely acting in the interest of the User and Third Party and should not be interpreted differently.
      </p>

      <p>8.2 If the User supplies an incorrect electrical meter number and Prepaid24 effects the recharge on this wrong
        number, the transaction cannot be reversed. Prepaid24 does not accept liability for any losses so incurred.</p>

      <p>8.3 Users understand and agree that this website and the information, services, products, and materials
        available through it are provided on an "as is" and "as available" basis and without any service levels. Users
        expressly agree that use of this website is at their own and sole risk.</p>

      <p>8.4 To the fullest extent permissible, according to applicable law, Prepaid24 and its affiliates disclaim all
        warranties of any kind, either express or implied, including but not limited to any warranties of title, or
        implied warranties of merchantability or fitness for a particular purpose. No oral or written information
        provided by Prepaid24 or its affiliates, officers, directors, employees, agents, providers, merchants, sponsors,
        licensors, or the like will create a warranty; nor may the User rely on any such advice or information.</p>

      <p>8.5 The User expressly agrees that use of this website, including all content, data or software distributed by,
        downloaded, or accessed from or through this website, is at his/her sole risk. The User understands and agrees
        that he/she will be solely responsible for any loss or damage to his/her business or his/her computer system or
        loss of data that results from the download of such content, data and/or software.
        8.6 The User acknowledges that Prepaid24 or any of its affiliates do not in any respect control any information,
        products or services offered by Third Parties on or through this website, except as otherwise agreed in writing.
        Prepaid24 and its affiliates assume no responsibility for and make no warranty or representation as to the
        accuracy, currency, completeness, reliability or usefulness of content or products distributed or made available
        by Third Parties through this website.
      <p>

      <p>8.7 Neither Prepaid24 nor any affiliate makes any warranty that this website or its contents will meet the
        User's requirements, or that the site or content will be uninterrupted, timely, secure, or error-free, or that
        defects, if any, will be corrected. Prepaid24 does not represent or warrant that materials in this website or
        information provided by Prepaid24 via email or other means, are accurate, complete, reliable, current or error
        free. Nor does Prepaid24 make any warranty as to the results that may be obtained from the use of Prepaid24 or
        its content or to the accuracy, completeness or reliability of any information obtained through use of this
        website. Prepaid24 will not be liable for the acts or omissions of any Third Party.</p>

      <p>8.8 Prepaid24 assumes no responsibility for any loss or damages suffered by the User, including, but not
        limited to, loss of data from delays, non-deliveries of content, SMS's or email, network or system outages, file
        corruption or service interruptions caused by the negligence of Prepaid24, its affiliates or the User's own
        errors and/or omissions. Prepaid24 disclaims any warranty or representation that confidentiality of information
        transmitted through this website will be maintained. All the information appearing on this website is provided
        without a representation or warranty whatsoever, whether expressed or implied, and Prepaid24 and its affiliates
        disclaims any liability to the User in this regard.</p>

      <p><strong>9. Disputes</strong></p>
      <p>The Parties agree irrevocably that any dispute whatsoever arising from these terms and conditions will be
        settled as follows:</p>

      <p>9.1 The Parties will firstly use their Commercially Reasonable Endeavours to settle the dispute amicably
        between them through negotiation. This entails that the one Party invites the other in writing to a meeting to
        attempt to resolve the dispute within thirty (30) days from the date of written invitation;</p>

      <p>9.2 Should such negotiation fail to resolve the dispute; the Parties irrevocably agree that the dispute will be
        referred to administered mediation upon the terms set by the Government of Zimbabwe;</p>

      <p>9.4 The arbitrator will have regard to the desire of the Parties to dispose of such dispute expeditiously,
        economically and confidentially; and will be obliged to provide written reasons for his decision.</p>

      <p>9.5 The Parties irrevocably agree that the decision in the arbitration proceedings will be final and binding on
        the Parties and will be carried into effect; and may be made an order of any court of competent jurisdiction.
      </p>

      <p>9.6 The nature of any dispute arising from this Agreement and the resolution thereof in terms of this clause,
        which will include any testimony and/or evidence presented in terms of the provisions of this dispute resolution
        clause, and any settlement agreement in terms of this clause as well as any arbitration award, will be held in
        confidence between the Parties.</p>
      <p>This clause will not preclude either Party from obtaining interim relief on an urgent basis from a court of
        competent jurisdiction pending the outcome of the negotiations, mediation or decision of the arbitrator(s) as
        the case may be from time to time. The provisions of this clause will continue to be binding on the Parties
        notwithstanding any termination or cancellation of this Agreement.</p>

      <p><strong>10. Limitation of liability</strong></p>
      <p>10.1 Prepaid24 will not be liable to any User for (a) indirect, consequential, punitive or special damages
        and/or (b) loss of income or profits, lost opportunities, or loss of anticipated savings, or loss of data,
        howsoever arising, whether or not caused by its employees, agents and/or contractors, and regardless of form or
        cause of action and whether the loss or damages was foreseen or not.</p>

      <p>10.2 Subject to clause10.1, the aggregate liability of Pepaid24 to the User, in any circumstances whatsoever,
        whether in contract, delict, statute or otherwise, and howsoever caused, for loss or damage arising from or in
        connection with the Service, whether or not caused by its employees, agents and/or contractors, and regardless
        of form or cause of action, and whether the loss or damages was foreseen or not, will be limited to USD$300
        (three hundred dollars).</p>

      <p>10.3 Prepaid24 or any affiliate will have no liability to the User in connection with any product, service or
        otherwise, purchased or used as a result of this website or the Service. It is expressly understood that the
        User is aware that Prepaid24 provides the Service to bring service provider and service consumer together and
        accepts no responsibility for the quality, reliability, safety, function, sustainability or otherwise, of a
        product purchased, service used, or otherwise, as a result of the use of this website.</p>

      <p>11. Standard terms and conditions applicable to all competitions conducted/promoted partly or wholly by or
        associated with Prepaid24:</p>

      <p>11.1 Only persons of 18 years and older may participate. Employees of Prepaid24 and immediate family members
        are excluded.</p>

      <p>11.2 The winner(s) will be the person(s) drawn by an independent auditor.</p>

      <p>11.3 The winner will receive his/her prize as stipulated on Prepaid24's social media platforms and website.</p>

      <p>11.4 The prize is not transferrable or negotiable.</p>

      <p>11.5 Multiple entries per person is allowed. Entries limited to 50 per person.</p>

      <p>11.6 The draw will take place on the 29th of each competition month.</p>
      <p>11.7 The winner will be announced on Prepaid24's Facebook page on the last day of the applicable month.</p>

      <p>11.8 Prepaid24 reserves the right to amend the rules or cancel the competition at any time.</p>

      <p><strong>12. Product validity periods:</strong>
      <p>
      <p>12.1 Google Play Gift Codes: gift card value expires 2 years following the date of: (i) card issuance; or (ii)
        last activity on Google Play stored value account into which the card is redeemed, whichever is later.</p>

      <p>12.2 Uber and UberEats vouchers: The value associated with this voucher code expires three years after the date
        of first activation.</p>

      <p>12.3 Netflix vouchers: product is valid for 3 years after date of purchase.</p>

      <p>12.4 Microsoft products: product is valid for 3 years after date of purchase.</p>

      <p>12.5 Spotify subscriptions: product is valid for 3 years after date of purchase.</p>

      <p>12.6 Showmax Vouchers: product is valid for 3 years after date of purchase.</p>

      <p>12.7 All Gaming Products (i.e., Xbox, Sony PlayStation (wallet top-ups & membership gift cards), Steam Wallet,
        Minecoins, PUBG Mobile, and Roblox): products are valid for 3 years after date of purchase.</p>

      <p>12.8 All Retail Vouchers (i.e., PnP, Makro, NetFlorist, and Sorbet): products are valid for 3 years after date
        of purchase.</p>

      <p><strong>13. Product refunds:</strong></p>
      <p>If the User's desired product cannot be delivered to him/her, Prepaid24 will offer a refund for the payment. No
        refunds will be offered if the User's purchase was successful.</p>

      <p><strong>14. PlayStation Plus memberships:</strong></p>
      <p>For PlayStation Plus 3 Months Membership and PlayStation Plus 12 Months Membership, 1c is added to the purchase
        total in order for Prepaid24 to process the transaction.</p>

      <p><strong>15. General</strong></p>
      <p>15.1 This Agreement constitutes the whole agreement between the Parties relating to the subject matter hereof.
        No amendment, alteration, variation, novation or consensual cancellation of this Agreement (including this
        clause 15) or addition thereto will be of any force or effect unless reduced to writing and signed by the
        Parties.</p>

      <p>15.2 No waiver of, or failure, delay, relaxation or indulgence on the part of either Party in exercising any
        power or right conferred on such Party in terms of this Agreement will operate as a waiver of such right, unless
        it is expressed in writing and signed by the Party giving the same, and it will be effective only in the
        specific instance and for the purpose given. No single or partial exercise of any such power or right will
        preclude any other or further exercises of that power or right in terms of this Agreement.</p>

      <p>15.3 If a court declares a provision of this Agreement to be invalid, the validity of the other provisions
        remains unaffected.</p>

      <p>15.4 Any provision of this Agreement that contemplates performance or observance subsequent to termination or
        expiration of this Agreement or which must necessarily continue to have effect after such expiration or
        termination, will survive the termination or expiration of this Agreement, notwithstanding that the provision
        itself does not expressly provide for this.</p>

      <p>15.5 Nothing in this Agreement constitutes either Party as the agent, principal, representative, joint-venturer
        or partner of the other, and neither Party may represent the contrary to any third party. The relationship
        between the Parties will not be deemed to be one of employer and employee, whether in terms of the common law or
        any legislation that applies to an employer-employee relationship.</p>

      <p>15.6 This Agreement supersedes all other agreements relating to the subject matter of this Agreement.</p>

      <p>15.7 Neither Party may rely on any representation made prior to the conclusion of this Agreement, even if such
        representation induced the Party that wishes to rely on it, to conclude the Agreement, unless that
        representation is recorded in this Agreement.</p>

      <p>15.8 This Agreement will be construed and given effect to in terms of the laws of Zimbabwe.</p>

    </div>
  </div>
</section>
<!-- quality-system-section -->

<!-- logo-section -->
<div class="w-100 float-left logo-con">
  <div class="container">
    <div class="logo-inner-con">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4">
          <figure class="mb-0">

          </figure>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- logo-section -->
<!-- weight-footer-section -->
<section>
  <div class="w-100 float-left weight-footer-con">
    <div class="container">
      <div class="weight-footer-inner-con">
        <div class="row">
          <div class="col-lg-5 col-12 text-xl-left text-lg-left text-center">
            <div class="weight-footer-content ">
              <figure>
                <img src="assets/static/image/Prepaid24-Logo.png" alt="Prepaid24 Zimbabwe" class="img-fluid">
              </figure>
              <p class="col-lg-11 col-md-7 pl-0 pr-0 ml-lg-0 mr-lg-0 ml-md-auto mr-md-auto">Online Bill Payments, Buy
                ZESA tokens, Pay School & University fees, Book bus tickets, Get Airtime Top-up, Buy lotto vouchers, and
                so much more.</p>
              <div class="weight-social-list">
                <ul class="list-unstyled mb-0">
                  <li class="d-inline-block"><a href="https://www.facebook.com/"><i
                        class="fab fa-facebook-square d-flex align-items-center justify-content-center text-white pr-0"></i></a>
                  </li>
                  <li class="d-inline-block"><a href="https://twitter.com/?lang=en"><i
                        class="fab fa-twitter-square d-flex align-items-center justify-content-center text-white pr-0"></i></a>
                  </li>
                  <li class="d-inline-block"><a href="https://www.linkedin.com/signup"><i
                        class="fab fa-linkedin d-flex align-items-center justify-content-center text-white pr-0"></i></a>
                  </li>
                  <li class="d-inline-block"><a href="https://www.pinterest.com/"><i
                        class="fab fa-pinterest-square mr-0 d-flex align-items-center justify-content-center text-white pr-0"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-4 col-6">
            <div class="weight-footer-content">
              <h5>Important Link</h5>
              <ul class="list-unstyled mb-0">
                <li><i class="fas fa-caret-right"></i><a routerLink="/about-us">About Us</a></li>
                <li><i class="fas fa-caret-right"></i><a routerLink="/about-us">Terms &amp; Conditions</a></li>
                <li><i class="fas fa-caret-right"></i><a routerLink="/privacy-policy">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-3 col-6">
            <div class="weight-footer-content">
              <h5>Support</h5>
              <ul class="list-unstyled mb-0">
                <li><i class="fas fa-caret-right"></i>Support</li>
                <li><i class="fas fa-caret-right"></i>Contact Us</li>
                <li><i class="fas fa-caret-right"></i>Faq</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-5 col-sm-5 col-12">
            <div class="weight-footer-content">
              <h5>Get in Touch</h5>
              <ul class="list-unstyled mb-0">
                <li><span>Email: </span>info&#64;prepaid24.co.zw</li>
                <li><span>Phone: </span>+263 777 007 007</li>
                <li><span>WhatsApp: </span>+263 777 007 007</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- weight-footer-section -->
<!-- footer-section -->
<div class="w-100 float-left footer-con text-xl-left text-lg-left text-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <p class="mb-0">Copyright ©2024 Prepaid24.co.zw All Rights Reserved</p>
      </div>
      <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center">
        <figure class="mb-0 d-inline-block">
          <img src="assets/static/image/payment-img1.png" alt="payment-img" class="img-fluid">
        </figure>
        <figure class="mb-0 d-inline-block">
          <img src="assets/static/image/payment-img2.png" alt="payment-img" class="img-fluid">
        </figure>
        <figure class="mb-0 d-inline-block">
          <img src="assets/static/image/payment-img3.png" alt="payment-img" class="img-fluid">
        </figure>
        <figure class="mb-0 d-inline-block mr-0">
          <img src="assets/static/image/payment-img4.png" alt="payment-img" class="img-fluid">
        </figure>
      </div>
    </div>
  </div>
</div>
<!-- footer-section -->
