<!-- <app-spinner></app-spinner>
 -->
<div [id]="id" class="modalDialog" (click)="closeModal()"  >
  <div class="controls  p-2" appFreeDragging>
    <h4 class="d-flex aligns-items-center justify-content-between">
      {{title}}
      <span>
        <span (click)="openModal()" class="cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
            class="bi bi-arrow-up-left-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-5.904 2.803a.5.5 0 1 0 .707-.707L6.707 6h2.768a.5.5 0 1 0 0-1H5.5a.5.5 0 0 0-.5.5v3.975a.5.5 0 0 0 1 0V6.707l4.096 4.096z" />
          </svg>
        </span>
        <span (click)="closeModal()" class="mx-1 cursor-pointer">
          <svg id="Component_9_19 " data-name="Component 9 – 19 " xmlns="http://www.w3.org/2000/svg " width="14 "
            height="14 " viewBox="0 0 34 34 ">
            <g id="Ellipse_12 " data-name="Ellipse 12 " fill="#fff " stroke="#707070 " stroke-width="2 ">
              <circle cx="17 " cy="17 " r="17 " stroke="none " />
              <circle cx="17 " cy="17 " r="16 " fill="none " />
            </g>
            <path id="_Color " data-name=" ↳Color "
              d="M13.57,7.754H7.754V13.57H5.816V7.754H0V5.816H5.816V0H7.754V5.816H13.57Z "
              transform="translate(16.97 7.375) rotate(45) " fill="#707070 " />
          </svg>
        </span>
      </span>
    </h4>
  </div>

  <div class="dialog position-relative" (click)="stopEventProp($event)" [ngClass]="[size]">
    <span title="Close " class="close">
      <span class="cursor-pointer mx-2 " (click)="minimizeModal()" *ngIf="minimizeButton">
        <svg xmlns="http://www.w3.org/2000/svg " width="24 " height="24 " fill="currentColor "
          class="bi bi-dash-circle " viewBox="0 0 16 16 ">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z " />
          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z " />
        </svg>
      </span>

      <span (click)="closeModal()" *ngIf="closeButton" class="cursor-pointer ">
        <svg id="Component_9_19 " data-name="Component 9 – 19 " xmlns="http://www.w3.org/2000/svg " width="24 "
          height="24 " viewBox="0 0 34 34 ">
          <g id="Ellipse_12 " data-name="Ellipse 12 " fill="#fff " stroke="#707070 " stroke-width="2 ">
            <circle cx="17 " cy="17 " r="17 " stroke="none " />
            <circle cx="17 " cy="17 " r="16 " fill="none " />
          </g>
          <path id="_Color " data-name=" ↳Color "
            d="M13.57,7.754H7.754V13.57H5.816V7.754H0V5.816H5.816V0H7.754V5.816H13.57Z "
            transform="translate(16.97 7.375) rotate(45) " fill="#707070 " />
        </svg>
      </span>
    </span>
    <h4 *ngIf="title">{{title}}</h4>
    <div class="my-modal-content" app-scroll-bar>
      <ng-content></ng-content>
    </div>
  </div>
</div>
