<mat-stepper orientation="vertical" [linear]="true" #stepper>
  <mat-step [stepControl]="accountDetails" [optional]="false">
    <h4 *ngIf="product">{{ product.productName }}</h4>
    <form [formGroup]="accountDetails">
      <ng-template matStepLabel>Enter Account Details</ng-template>
      <mat-form-field>
        <mat-label>{{account}} number</mat-label>
        <input matInput placeholder="0123456789" formControlName="accountNumber" required>
      </mat-form-field>
      <div class="action">
        <span>&nbsp;</span>

        <button [disabled]="isLoading || accountDetailsForm['accountNumber']['errors'] && accountDetailsForm['accountNumber']['errors']['required']" mat-fab extended color="primary" (click)="getCustomerDetails(stepper)">
          <span [hidden]="isLoading">
            Next
          </span>
          <mat-icon *ngIf="isLoading" class="loading">hdr_weak</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="customerDetails" label="Confirm Account" [optional]="false">
    <form [formGroup]="customerDetails">
      <div class="account-details">
        <p *ngFor="let detail of details">{{detail}}</p>
      </div>
      <p><input type="checkbox" formControlName="confirmed" (click)="confirm()">The account details are correct.</p>
      <div class="action">
        <button mat-fab extended matStepperPrevious>Back</button>
        <button [disabled]="isLoading || !checked" mat-fab extended color="primary" (click)="confirmDetail(stepper)">
          <span [hidden]="isLoading">
            Next
          </span>
          <mat-icon *ngIf="isLoading" class="loading">hdr_weak</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <ng-container *ngIf="product && product.productPackage && product.productPackage.length > 0">
    <mat-step [stepControl]="packageDetails" label="Choose A Package" [optional]="false">
      <form [formGroup]="packageDetails">
        <div class="account-details">
          <mat-radio-group aria-label="Select a Package" formControlName="package">
            <mat-radio-button *ngFor="let package of product.productPackage" [value]="package.productPackageId">{{package.productPackageDescription}} <strong>{{package.productPackageCost / 100 | currency}}</strong></mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="action">
          <button mat-fab extended matStepperPrevious>Back</button>
          <button [disabled]="isLoading || packageDetailsForm['package']['errors'] && packageDetailsForm['package']['errors']['required']" mat-fab extended color="primary" (click)="confirmPackage(stepper)">
            <span [hidden]="isLoading">
              Next
            </span>
            <mat-icon *ngIf="isLoading" class="loading">hdr_weak</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
  </ng-container>
  <ng-container *ngIf="product && product.productAddons && product.productAddons.length > 0">
    <mat-step [stepControl]="packageDetails" label="Choose Addons" [optional]="false">
      <ng-container *ngIf="selectedPackage">
        <h4 style="margin-top: 1%; border-bottom: 1px solid #ACACAC; width: auto;">{{selectedPackage.productPackageDescription}} <strong>{{selectedPackage.productPackageCost / 100 | currency}}</strong></h4>

      </ng-container>
      <form [formGroup]="addonDetails">
        <div class="account-details">
          <p *ngFor="let addon of product.productAddons"><input type="checkbox" (click)="activateAddons(addon)">{{addon.productAddonsDescription}} <strong>{{addon.productAddonsCost / 100 | currency}}</strong></p>
        </div>
        <div class="action">
          <button mat-fab extended matStepperPrevious>Back</button>
          <button [disabled]="isLoading" mat-fab extended color="primary" (click)="confirmAddons(stepper)">
            <span [hidden]="isLoading">
              Next
            </span>
            <mat-icon *ngIf="isLoading" class="loading">hdr_weak</mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
  </ng-container>
  <mat-step [stepControl]="amount" label="Enter Amount">
    <form [formGroup]="amount">
        <mat-form-field>
          <mat-label>Amount</mat-label>
          <input type="number" matInput formControlName="amount" placeholder="5.00" required [attr.disabled]="isSelected()">
        </mat-form-field>
      <ng-container *ngIf="product && product.productPackage">
        <!-- <mat-form-field>
          <mat-label>Amount</mat-label>
          <input type="number" matInput formControlName="amount" placeholder="5.00" [attr.disabled]="true">
        </mat-form-field> -->
        <mat-form-field>
          <mat-label>Quantity</mat-label>
          <input type="number" matInput formControlName="quantity" placeholder="1" required>
        </mat-form-field>
      </ng-container>
      <div class="action">
        <button mat-fab extended matStepperPrevious>Back</button>
        <button [disabled]="amountForm['amount']['errors'] && amountForm['amount']['errors']['required']" mat-fab extended color="primary" (click)="setAmount(stepper)">
          <span [hidden]="isLoading">
            Next
          </span>
          <mat-icon *ngIf="isLoading" class="loading">hdr_weak</mat-icon></button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Make Payment</ng-template>
    <p>You are almost done. Enter the contact details where you will receive notifications.</p>
    <form [formGroup]="contactDetails">
      <mat-form-field>
        <mat-label>Phone number</mat-label>
        <input matInput formControlName="phoneNumber" placeholder="263772712733"
               required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email address</mat-label>
        <input matInput formControlName="email" type="email"
               required>
      </mat-form-field>
      <div class="action">
        <button mat-fab extended matStepperPrevious>Back</button>
        <button [disabled]="contactDetailsForm['phoneNumber']['errors'] && contactDetailsForm['phoneNumber']['errors']['required']" mat-fab extended color="primary"  (click)="makePayment()">
          <span [hidden]="isLoading">
            Pay Now
          </span>
          <mat-icon *ngIf="isLoading" class="loading">hdr_weak</mat-icon></button>
      </div>
    </form>
  </mat-step>
</mat-stepper>
