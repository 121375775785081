import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerDetails } from '../../models/bill-payments/customers/customer-details';
import { Invoice } from '../../models/bill-payments/invoices/invoice';
import { InvStatus, InvoiceStatus, ReceiptDetails } from '../../models/bill-payments/invoices/invoice-status';
import { BillPaymentsService } from '../../services/bill-payments.service';
import { LoaderService } from '../../services/loader.service';
import { InvoicesService } from '../../services/invoices.service';
import { Error } from '../../models/common/i-error';

@Component({
  selector: 'app-checkout-result',
  templateUrl: './checkout-result.component.html',
  styleUrl: './checkout-result.component.scss'
})
export class CheckoutResultComponent {

  id!: string;

  invoice!: Invoice;

  invoiceStatus!: InvoiceStatus;

  invStatus!: InvStatus;

  receiptDetails!: ReceiptDetails;

  isLoading = false;

  customerDetails!: CustomerDetails;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoicesService,
    private billpaymentService: BillPaymentsService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id')!;
      if (this.id.length > 0) {
        this.updateStatus(this.id);
      }
    });
  }

  updateStatus(invoiceNumber: string) {
    this.loader.showLoader();

    this.invoiceService.updateInvoiceStatus(invoiceNumber).subscribe({
      next: (invoiceStatus) => {
        console.log('Invoice status', invoiceStatus);

        this.isLoading = false;
        //this.invoiceStatus = invoiceStatus;

        this.invStatus = invoiceStatus;

        if(this.invStatus.supplierName.toLowerCase() == "zetdc") {
          this.getZESAReceiptDetails(this.invStatus.invoiceDescription)
        }

        console.log("Receipt details", this.receiptDetails);

        console.log("formated token", this.formatToken(this.receiptDetails.token[0]));


        // this.getInvoice(invoiceNumber, invoiceStatus.accountNumber);
        // this.getCustomerDetails();
      },
      error: (e: Error) => {
        window.alert('Error ' + e.error.code + ': ' + e.error.message);

        console.log('Error yacho', e)
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }

  // const formattedValue = digits.replace(/(.{4})/g, '$1 ');

  getZESAReceiptDetails(receipt: string) {
    let details = receipt.split("|");
    console.log(details);
    this.receiptDetails = {
      date: details[0],
      time: details[1],
      receiptNumber: details[2],
      meterNumber: details[3],
      customerName: details[4],
      addressLine1: details[5],
      addressLine2: details[6],
      city: details[7],
      province: details[8],
      token: details[9].split("#"),
      tariff: details[10].split('#'),
      energyBought: details[11],
      tenderAmount: details[12],
      energyCharge: details[13],
      debtCollected: details[14],
      RElevy: details[15],
      RElevyAmount: details[16],
      VAT: details[17],
      VATAmount: details[18],
      totalPaid: details[19],
      debtBalanceBroughtForward: details[20],
      debtBalanceAfterDeduction: details[21],
      tariffIndex: details[22],
      supplyGroupCode: details[23],
      keyRevNumber: details[24],
    }
  }

  formatToken(token: string): string {
    const formattedValue = token.replace(/(.{4})/g, '$1 ');
    return formattedValue;
  }

  formatAmount(amount: string): number {
    return parseFloat(amount) /100;
  }

  getInvoice(invoiceNumber: string, accountNumber: string) {
    this.invoiceService.getInvoice(invoiceNumber, accountNumber).subscribe({
      next: (invoice) => {
        this.invoice = invoice;
        this.loader.hideLoader();
      },
      error: (e: Error) => {
        window.alert('Error ' + e.error.code + ': ' + e.error.message);
        this.loader.hideLoader();
      },
      complete: () => {
        this.loader.hideLoader();
      },
    });
  }

  getCustomerDetails() {
    this.billpaymentService
      .getCustomerDetails(this.invoiceStatus.supplierName.toLowerCase(), this.invoiceStatus.accountNumber)
      .subscribe({
        next: (customerDetails) => {
          this.customerDetails = customerDetails;
        },
        error: (e: Error) => {
          window.alert('Error ' + e.error.code + ': ' + e.error.message);
          this.loader.hideLoader();
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
  }

  backToHome() {
    this.router.navigate(['/']);
  }

}
