<!-- <app-spinner></app-spinner>
 -->
<div class="invoice-detail">
  <div class="controls">
    <img src="assets/images/success.svg" />


    <button mat-fab extended>Print</button>

    <button mat-fab extended>Download</button>

    <button mat-fab extended color="primary" (click)="backToHome()">
      Back To Home
    </button>
  </div>

  <div class="invoice">
    <img src="assets/images/prepaid24.png" />

    <h1>Remit Sixty Private Ltd t/a prepaid24.co.zw</h1>
    <p>449 Habane, Esigodini, Zimbabwe</p>

    <div class="detail">
      <span class="strong">
        Supplier TIN:
      </span>
      <span>
        2001802542
      </span>

    </div>

    <mat-divider></mat-divider>

    <ng-container *ngIf="invStatus">

      <h1 style="margin-top: 5%;">TAX INVOICE</h1>
      {{ id }}

      <div class="detail">
        <span class="strong">
          Receipt Number:
        </span>
        <span>
          {{ invStatus.receiptNumber }}
        </span>

        <span class="strong">
          Date:
        </span>
        <span>
          {{ invStatus.createdAt | date: 'yyyy-MM-dd' }} {{ invStatus.createdAt | date: 'HH:mm:ss' }}
        </span>

        <span class="strong">
          Biller:
        </span>
        <span>
          {{ invStatus.supplierName }}
        </span>

        <ng-container *ngIf="invStatus.supplierName !== 'ZETDC'">

          <span class="strong">
            Description:
          </span>
          <span>
            {{ invStatus.invoiceDescription }}
          </span>
        </ng-container>

        <span class="strong">
          Amount:
        </span>
        <span>
          {{ invStatus.currency }} {{ invStatus.amount / 100 | number: '1.2-2' }}
        </span>
      </div>

      <ng-container *ngIf="customerDetails">
        <p *ngFor="let detail of customerDetails.customerDetails">
          {{ detail }}
        </p>
      </ng-container>

      <ng-container *ngIf="invStatus.supplierName === 'ZETDC'">
        <p><strong>ELECTRICITY TOKEN/STAFF ELECTRICITY BENEFIT</strong></p>
        <p>Enter these codes into your meter:</p>
        <p *ngFor="let token of receiptDetails.token">
          {{formatToken(token)}}
        </p>

        <div class="detail" style="margin-top: 5%;">
          <span class="strong">
            Tariff: ({{invStatus.currency}})
          </span>
          <span>
            <p *ngFor="let tariff of receiptDetails.tariff">
              {{tariff}}
            </p>
          </span>

          <span class="strong">
            Energy Bought (kWH):
          </span>
          <span>
            {{ receiptDetails.energyBought }}
          </span>

          <span class="strong">
            Energy Charge:
          </span>
          <span>
            {{ formatAmount(receiptDetails.energyCharge) | currency }}
          </span>

          <span class="strong">
            Debt Collected:
          </span>
          <span>
            {{ formatAmount(receiptDetails.debtCollected) | currency }}
          </span>

          <span class="strong">
            RE Levy ({{receiptDetails.RElevy}} ):
          </span>
          <span>
            {{ formatAmount(receiptDetails.RElevyAmount) | currency}}
          </span>

          <span class="strong">
            VAT ({{receiptDetails.VAT}}%):
          </span>
          <span>
            {{ formatAmount(receiptDetails.VATAmount) | currency }}
          </span>

          <span class="strong">
            TOTAL PAID:
          </span>
          <span>
            {{ formatAmount(receiptDetails.totalPaid) | currency }}
          </span>

          <span class="strong">
            TOTAL TENDERED:
          </span>
          <span>
            {{ formatAmount(receiptDetails.tenderAmount) | currency }}
          </span>

          <span class="strong">
            Debt Balance B/fwd:
          </span>
          <span>
            {{ formatAmount(receiptDetails.debtBalanceBroughtForward) | currency }}
          </span>

          <span class="strong">
            Debt Balance After Payment:
          </span>
          <span>
            {{ formatAmount(receiptDetails.debtBalanceAfterDeduction) | currency }}
          </span>

          <span class="strong">
            Tariff Index:
          </span>
          <span>
            {{ receiptDetails.tariffIndex }}
          </span>

          <span class="strong">
            Supply Group Code:
          </span>
          <span>
            {{ receiptDetails.supplyGroupCode }}
          </span>

          <span class="strong">
            Key Rev Number:
          </span>
          <span>
            {{ receiptDetails.keyRevNumber }}
          </span>

        </div>

      </ng-container>



      <!-- <p *ngIf="invStatus && invStatus.tokens && invStatus.tokens.length > 3">
        Token: {{ invStatus.tokens }}
      </p> -->
    </ng-container>
  </div>
</div>
