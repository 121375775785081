<!-- header-and-banner-section -->
<div class="w-100 float-left header-and-banner-con banner-overlay-img">
  <div class="container">
     <div class="overlay-img">
        <!-- navbar-start -->
        <nav class="navbar navbar-expand-lg navbar-light">
           <a class="navbar-brand" routerLink="/"><img src="assets/static/image/Prepaid24-Logo-White.png" alt="Prepaid24" class="img-fluid"></a>
           <button class="navbar-toggler p-0 collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
           <span class="navbar-toggler-icon"></span>
           <span class="navbar-toggler-icon"></span>
           <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto mr-auto">
                 <li class="nav-item active ">
                    <a class="nav-link p-0 text-white" routerLink="/">Pay Bills</a>
                 </li>
      <li class="nav-item">
                    <a class="nav-link p-0 text-white" routerLink="/about-us">About Us</a>
                 </li>
                 <li class="nav-item">
                    <a class="nav-link p-0 text-white" routerLink="/privacy-policy">Privacy Policy</a>
                 </li>
     <li class="nav-item">
                    <a class="nav-link p-0 text-white" routerLink="/terms">Terms &amp; Conditions</a>
                 </li>
              </ul>
              <a href="tel:+263777007007" class="navbar-btn text-white">
              <i class="fas fa-phone-volume"></i>
              +263 777 007 007
              </a>
           </div>
        </nav>
        <!-- navbar-end -->
        <!-- banner-start -->
        <section>
           <div class="w-100 float-left generic-banner-con text-xl-left text-lg-left text-center">
              <div class="container">
                 <div class="generic-banner-content text-white text-center">
                    <h1>Privacy Policy</h1>
                 </div>
              </div>
           </div>
     </section>
        <!-- banner-end -->
     </div>
  </div>
</div>
<!-- header-and-banner-section -->
<!-- quality-system-section -->
<section>
  <div class="w-100 float-left quality-system-con Experince-section">
     <div class="container">
                 <p> READ THIS POLICY CAREFULLY BEFORE BROWSING THIS WEBSITE OR USING ANY OF THE SERVICES. YOUR CONTINUED USE OF THIS WEBSITE INDICATES THAT YOU HAVE BOTH READ AND AGREE TO THE TERMS OF THIS POLICY. YOU CANNOT USE THIS WEBSITE IF YOU DO NOT ACCEPT THIS POLICY. ALL SECTIONS OF THIS POLICY ARE APPLICABLE TO BOTH MEMBERS AND GUESTS UNLESS THE SECTION EXPRESSLY STATES OTHERWISE.</p>

<p><strong>1 Privacy Policy</strong> A Tuesday or a Thursday that is not a public holiday in South Africa.</p>

<p>1.1 In adopting this Privacy Policy, we wish to balance our legitimate business interests and your reasonable expectation of privacy. Accordingly, we will take all reasonable steps to prevent unauthorised access to, or disclosure of your Personal Information. However, it is impossible to guarantee that your Personal Information shall be 100% secure..</p>

<p>1.2 Users will be prompted to register an account with Prepaid24. In so doing, users may be asked to provide the following information (“Personal Information”):</p>

<p>1.2.1 First name and</p>
<p>1.2.2 Surname and</p>
<p>1.2.3 Physical address and</p>
<p>1.2.4 Phone number and</p>
<p>1.2.5 Bank account details and</p>
<p>1.2.6 Company / CC / business name (if applicable) and</p>
<p>1.2.7 Company / CC / business registration number / VAT Registration Number (if applicable).</p>
<p>1.3 We will attempt to limit the types of Personal Information we process to only that to which you consent to (for example, in the context of online registration, newsletters, message boards, surveys, polls, professional announcements, SMS, MMS and other mobile services), but, to the extent necessary, your agreement to these Terms constitutes your consent as contemplated in section 69 of the Act. .</p>

<p>1.4 All payment information, apart from payments effected by way of electronic funds transfer, will be captured through the Website through a third party payment gateway, currently, Payfast. Further, Prepaid24 may retain payment information on behalf of its users.</p>

<p>1.5 We will not collect, use or disclose sensitive information (such as information about racial or ethnic origins or political or religious beliefs, where relevant) except with your specific consent or in the circumstances permitted by law.</p>

<p>1.6 By agreeing to the terms contained in this Policy, you consent to the use of your Personal Information in relation to:</p>

<p>1.6.1 The provision and performance of the Services, as defined in our Terms of Services located at www.prepaid24.co.zw/terms-conditions</p>

<p>1.6.2 Informing you of changes made to our Website</p>

<p>1.6.3 The provision of marketing related services to you by the Company</p>

<p>1.6.4 Responding to any queries or requests you may have</p>

<p>1.6.5 Developing a more direct and substantial relationship with users for the purposes described in this clause</p>

<p>1.6.6 Developing an online user profile</p>
<p>1.6.7 Understanding general user trends and patterns so that we can develop and support existing and ongoing marketing strategies</p>

<p>1.6.8 For security, administrative and legal purposes and</p>

<p>1.6.9 The creation and development of market data profiles which may provide insight into market norms, practices and trends to help us improve our offering to you. Such information will be compiled and retained in aggregated form, but shall not be used in any way which may comprise the identity of a user.</p>

<p>1.7 Although absolute security cannot be guaranteed on the internet, the Company has in place up-to-date, reasonable technical and organisational security measures to protect your Personal Information against accidental or intentional manipulation, loss, misuse, destruction or against unauthorised disclosure or access to the information we process online.</p>

<p>1.8 While we cannot ensure or warrant the security of any Personal Information you provide us, we will continue to maintain and improve these security measures over time in line with legal and technological developments.</p>

<p>1.9 We store your Personal Information directly, or alternatively, store your Personal Information on, and transfer your Personal Information to, a central database. If the location of the central database is located in a country that does not have substantially similar laws which provide for the protection of Personal Information, we will take the necessary steps to ensure that your Personal Information is adequately protected in that jurisdiction.</p>

<p>1.10 Your information will not be stored for longer than is necessary for the purposes described in these Terms or as required by applicable legislation.</p>

<p>1.11 The Personal Information the Company collects from users shall only be accessed by Prepaid24 employees, representatives and consultants on a need-to-know basis, and subject to reasonable confidentiality obligations binding such persons.</p>

<p>1.12 Prepaid24 shall have the right, but shall not be obliged, to monitor or examine any information and materials including any website link that you publish or submit to Prepaid24 for publishing on the Site.  You shall be solely responsible for the contents of all material published by yourself.</p>

<p>1.13 We constantly review our systems and data to ensure the best possible service to our users. We do not accept any users, or representatives of users, under 18 years of age or who otherwise does not have the relevant capacity to be bound by these Terms.</p>

<p>1.14 You hereby consent to the sale, sharing, or rental of your Personal Information, apart from payment information, to third parties to the extent necessary. We will not use your e-mail address for unsolicited mail. Any emails sent by this Company will only be in connection with the provision of our Services and/or the marketing thereof.</p>

<p><strong>2 Log Files</strong></p>

<p>2.1 When you visit Prepaid24, even if you do not create an account, we may collect information, such as your IP address, the name of your ISP (Internet Service Provider), your browser, the website from which you visit us, the pages on our website that you visit and in what sequence, the date and length of your visit, and other information concerning your computer's operating system, language settings, and broad demographic information. This information is aggregated and anonymous data and does not identify you specifically. However, you acknowledge that this data may be able to be used to identify you if it is aggregated with other Personal Information that you supply to us. This information is not shared with third parties and is used only within the Company on a need-to-know basis. Any individually identifiable information related to this data will never be used in any way different to that stated above, without your explicit permission.</p>

<p><strong>3 Cookies</strong></p>

<p>3.1 Prepaid24 uses cookies. A cookie is a small piece of information stored on your computer or smart phone by the web browser. The two types of cookies used on the Website are described below:</p>

<p>3.1.1 "Session cookies": These are used to maintain a so-called 'session state' and only lasts for the duration of your use of the Website. A session cookie expires when you close your browser, or if you have not visited the server for a certain period of time. Session cookies are required for the Platform to function optimally, but are not used in any way to identify you personally.</p>
<p>3.1.2 "Permanent cookies": These cookies permanently store a unique code on your computer or smart device hard drive in order to identify you as an individual user. No Personal Information is stored in permanent cookies. You can view permanent cookies by looking in the cookies directory of your browser installation. These permanent cookies are not required for the Prepaid24 website to work, but may enhance your browsing experience.</p>

<p><strong>4 Links from prepaid24.co.zw</strong></p>

<p>4.1 Prepaid24, and the Services available through the Website, may contain links to other third party websites, including (without limitation) social media platforms, payment gateways, appointment scheduling and/or live chat platforms ("Third Party Websites"). If you select a link to any Third Party Website, you may be subject to such Third Party Website’s terms and conditions and/or other policies, which are not under the control, nor responsibility, of Prepaid24.</p>

<p>4.2 Hyperlinks to Third Party Websites are provided "as is", and Prepaid24 does not necessarily agree with, edit or sponsor the content on Third Party Websites.</p>

<p>4.3 Prepaid24 does not monitor or review the content of any Third Party Website. Opinions expressed or material appearing on such websites are not necessarily shared or endorsed by us and we should not be regarded as the publisher of such opinions or material. Please be aware that we are not responsible for the privacy practices, or content, of other websites, either.</p>

<p>4.4 Users should evaluate the security and trustworthiness of any Third Party Website before disclosing any personal information to them. Prepaid24 does not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.</p>

<p><strong>5 Electronic Communications And Transactions</strong></p>

<p>5.1 Data Messages (as defined in the ECT Act) will be deemed to have been received by Prepaid24 if and when Prepaid24 responds to the Data Messages.</p>

<p>5.2 Data Messages sent by Prepaid24 to a user will be deemed to have been received by such user in terms of the provisions specified in section 23(b) of the ECT Act.</p>

<p>5.3 Users acknowledge that electronic signatures, encryption and/or authentication are not required for valid electronic communications between users and the Company.</p>

<p>5.4 Information to be provided in terms of section 43(1) of the ECT Act:</p>
<p>5.4.1 Users warrant that Data Messages sent to Prepaid24 from any electronic device, used by such user, from time to time or owned by such user, were sent and or authorised by such user, personally.</p>

<p>5.4.2 This Website is owned and operated by Remit Sixty (PVT) Ltd, a private company owned and operated in accordance with the laws of the Republic of Zimbabwe.</p>

<p>5.4.3 Address for service of legal documents 449 Habane, Edigodini, Zimbabwe</p>

<p>5.4.4 Prepaid24 - located at www.prepaid24.co.zw</p>

<p>5.4.5 Email address: prepaid24&#64;remit60.co.za</p>


     </div>
  </div>
</section>
<!-- quality-system-section -->

<!-- logo-section -->
<div class="w-100 float-left logo-con">
  <div class="container">
     <div class="logo-inner-con">
        <div class="row">
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">

              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">

              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">

              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">

              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">

              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">

              </figure>
           </div>
        </div>
     </div>
  </div>
</div>
<!-- logo-section -->
<!-- weight-footer-section -->
<section>
  <div class="w-100 float-left weight-footer-con">
     <div class="container">
        <div class="weight-footer-inner-con">
           <div class="row">
              <div class="col-lg-5 col-12 text-xl-left text-lg-left text-center">
                 <div class="weight-footer-content ">
                    <figure>
                       <img src="assets/static/image/Prepaid24-Logo.png" alt="Prepaid24 Zimbabwe" class="img-fluid">
                    </figure>
                    <p class="col-lg-11 col-md-7 pl-0 pr-0 ml-lg-0 mr-lg-0 ml-md-auto mr-md-auto">Online Bill Payments, Buy ZESA tokens, Pay School & University fees, Book bus tickets, Get Airtime Top-up, Buy lotto vouchers, and so much more.</p>
                    <div class="weight-social-list">
                       <ul class="list-unstyled mb-0">
                          <li class="d-inline-block"><a href="https://www.facebook.com/"><i class="fab fa-facebook-square d-flex align-items-center justify-content-center text-white pr-0"></i></a></li>
                          <li class="d-inline-block"><a href="https://twitter.com/?lang=en"><i class="fab fa-twitter-square d-flex align-items-center justify-content-center text-white pr-0"></i></a></li>
                          <li class="d-inline-block"><a href="https://www.linkedin.com/signup"><i class="fab fa-linkedin d-flex align-items-center justify-content-center text-white pr-0"></i></a></li>
                          <li class="d-inline-block"><a href="https://www.pinterest.com/"><i class="fab fa-pinterest-square mr-0 d-flex align-items-center justify-content-center text-white pr-0"></i></a></li>
                       </ul>
                    </div>
                 </div>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                 <div class="weight-footer-content">
                    <h5>Important Link</h5>
                    <ul class="list-unstyled mb-0">
                       <li><i class="fas fa-caret-right"></i><a routerLink="/about-us">About Us</a></li>
                       <li><i class="fas fa-caret-right"></i><a routerLink="/about-us">Terms &amp; Conditions</a></li>
                       <li><i class="fas fa-caret-right"></i><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                 </div>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-3 col-6">
                 <div class="weight-footer-content">
                    <h5>Support</h5>
                    <ul class="list-unstyled mb-0">
                       <li><i class="fas fa-caret-right"></i>Support</li>
                       <li><i class="fas fa-caret-right"></i>Contact Us</li>
                       <li><i class="fas fa-caret-right"></i>Faq</li>
                    </ul>
                 </div>
              </div>
              <div class="col-lg-3 col-md-5 col-sm-5 col-12">
                 <div class="weight-footer-content">
                    <h5>Get in Touch</h5>
                    <ul class="list-unstyled mb-0">
                       <li><span>Email: </span>info&#64;prepaid24.co.zw</li>
                       <li><span>Phone: </span>+263 777 007 007</li>
                       <li><span>WhatsApp: </span>+263 777 007 007</li>
                    </ul>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- weight-footer-section -->
<!-- footer-section -->
<div class="w-100 float-left footer-con text-xl-left text-lg-left text-center">
  <div class="container">
     <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
           <p class="mb-0">Copyright ©2024 Prepaid24.co.zw All Rights Reserved</p>
        </div>
        <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center">
           <figure class="mb-0 d-inline-block">
              <img src="assets/static/image/payment-img1.png" alt="payment-img" class="img-fluid">
           </figure>
           <figure class="mb-0 d-inline-block">
              <img src="assets/static/image/payment-img2.png" alt="payment-img" class="img-fluid">
           </figure>
           <figure class="mb-0 d-inline-block">
              <img src="assets/static/image/payment-img3.png" alt="payment-img" class="img-fluid">
           </figure>
           <figure class="mb-0 d-inline-block mr-0">
              <img src="assets/static/image/payment-img4.png" alt="payment-img" class="img-fluid">
           </figure>
        </div>
     </div>
  </div>
</div>
<!-- footer-section -->
