import { Component } from '@angular/core';
import { Product } from '../../models/bill-payments/products/product';
import { LoaderService } from '../../services/loader.service';
import { ModalService } from '../../services/modal.service';
import { ProductsService } from '../../services/products.service';
import { IPage } from '../../models/common/i-page';
import { Error } from '../../models/common/i-error';


@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrl: './products-list.component.scss'
})
export class ProductsListComponent {

  products: Product[] = [];

  page!: IPage;

  pageId = 1;

  pageSize = 20;

  selectedProduct!: Product;

  isLoading = false;

  constructor(
    private productService: ProductsService,
    private modalService: ModalService,
    private loader: LoaderService
  ) {
    this.productService.products.asObservable().subscribe((products) => {
      if (products) {
        this.products = products;
      }
    });
    this.loader.isLoading$.subscribe((loading) => {
      this.isLoading = loading
    })
  }

  ngOnInit(): void {
    this.loader.showLoader();
    this.getProducts();
  }

  getProducts() {
    this.productService
      .getProductList(true, this.pageSize, this.pageId)
      .subscribe({
        next: (products) => {
          this.products = products.content;
          this.page = products;
          this.loader.hideLoader();
        },
        error: (e: Error) => {
          window.alert('Error ' + e.error.code + ': ' + e.error.message);
          this.loader.hideLoader();
        },
        complete: () => {
          this.loader.hideLoader();
        },
      });
  }

  buyProduct(product: Product) {
    this.selectedProduct = product;
    this.modalService.open('buyProduct');
  }

}
