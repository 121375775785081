import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  scrollToAnchor(anchorId: string) {

    const element = document.getElementById(anchorId);
    console.log('Clicked ', element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error("Anchor element with ID '" + anchorId + "' not found!");
    }
  }
}
