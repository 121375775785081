<!-- header-and-banner-section -->
<div class="w-100 float-left header-and-banner-con banner-overlay-img">
  <div class="container">
     <div class="overlay-img">
        <!-- navbar-start -->
        <nav class="navbar navbar-expand-lg navbar-light">
           <a class="navbar-brand" routerLink="/"><img src="assets/static/image/Prepaid24-Logo-White.png" alt="Prepaid24 Zimbabwe" class="img-fluid"></a>
           <button class="navbar-toggler p-0 collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
           <span class="navbar-toggler-icon"></span>
           <span class="navbar-toggler-icon"></span>
           <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto mr-auto">
              <li class="nav-item" style="cursor: pointer;">
                <a class="nav-link p-0 text-white" (click)="scrollToAnchor('bills')">Pay Bills</a>
              </li>
              <li class="nav-item">
                <a class="nav-link p-0 text-white" routerLink="/about-us">About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link p-0 text-white" routerLink="/privacy-policy">Privacy Policy</a>
              </li>
              <li class="nav-item">
                <a class="nav-link p-0 text-white" routerLink="/terms">Terms &amp; Conditions</a>
              </li>
            </ul>
              <a href="tel:+263778270270" class="navbar-btn text-white">
              <i class="fas fa-phone-volume"></i>
              +263 778 270 270
              </a>
           </div>
        </nav>
        <!-- navbar-end -->
        <!-- banner-start -->
        <section>
           <div class="w-100 float-left banner-con text-xl-left text-lg-left text-center">
              <div class="container">
                 <div class="row">
                    <div class="col-lg-6 order-xl-0 order-lg-0 order-2">
                       <div class="banner-left-con">
                          <figure class="mb-0 left-curve-img wow slideInLeft">
                             <img src="assets/static/image/small-left-curve-img.png" alt="" class="img-fluid">
                          </figure>
                          <figure class="mb-0">
                             <img src="assets/static/image/Home-Banner-Prepaid24.png" alt="Happy Prepaid24 Users" class="img-fluid banner-left-img">
                          </figure>
                          <!-- <figure class="mb-0 right-curve-img wow slideInRight">
                             <img src="assets/static/image/Home-Banner-Prepaid24.png" alt="Happy Prepaid24 Users" class="img-fluid">
                          </figure> -->
                       </div>
                    </div>
                    <div class="col-lg-6">
                       <div class="banner-right-content text-white">
                          <h5 style="font-size: 20px;">Prepaid24.co.zw</h5>
                          <h1 style="font-size: 60px; line-height: 1.3em;">Your All-in-One Bill Payments Gateway
                          </h1>
                          <p class="text-white col-lg-10 col-md-9 ml-xl-0 mr-xl-0 ml-lg-0 mr-lg-0 ml-auto mr-auto pl-0 pr-0"></p>

                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </section>
        <!-- banner-end -->
     </div>
  </div>
</div>
<!-- header-and-banner-section -->
<!-- feature-box -->

<a id="bills"></a>
<section>
  <div class="w-100 float-left feature-box-con text-center">

      <app-products-list></app-products-list>

  </div>
</section>
