<section>
  <div class="w-100 float-left feature-box-cont text-center">
     <div class="container">
        <div class="row bg-white">
           <div class="col-lg-4 col-md-4 col-sm-6 pl-0 pr-0">
              <div class="feature-box-item ">
                 <figure>
                    <img src="assets/static/image/BCC.png" alt="Bulawayo City Council" class="img-fluid">
                 </figure>
                 <h5>Bulawayo City Council</h5>
                 <p class="mb-0">Bill & Rate Payments</p>
              </div>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 pl-0 pr-0">
              <div class="feature-box-item ">
                 <figure>
                    <img src="assets/static/image/ZOL.png" alt="ZOL" class="img-fluid">
                 </figure>
                 <h5>ZOL</h5>
                 <p class="mb-0">ADSL & Fiber Internet</p>
              </div>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 pl-0 pr-0 border-bottom-0">
            <div class="feature-box-item">
               <figure>
                  <img src="assets/static/image/Econet.png" alt="Econet" class="img-fluid">
               </figure>
               <h5>Econet</h5>
               <p class="mb-0">Airtime, Data & SMS's</p>
            </div>
         </div>
        <!-- </div>
        <div class="row bg-white"> -->
           <div class="col-lg-4 col-md-4 col-sm-6 pl-0 pr-0 border-bottom-0">
              <div class="feature-box-item">
                 <figure>
                    <img src="assets/static/image/Netone.png" alt="Netone" class="img-fluid">
                 </figure>
                 <h5>Netone</h5>
                 <p class="mb-0">Airtime, Data & SMS's</p>
              </div>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 pl-0 pr-0 border-bottom-0">
              <div class="feature-box-item">
                 <figure>
                    <img src="assets/static/image/Telecel.png" alt="Telecel" class="img-fluid">
                 </figure>
                 <h5>Telecel</h5>
                 <p class="mb-0">Airtime, Data & SMS's</p>
              </div>
           </div>
           <div class="col-lg-4 col-md-4 col-sm-6 pl-0 pr-0 border-bottom-0 border-right-0">
              <div class="feature-box-item">
                 <figure>
                    <img src="assets/static/image/Telone.png" alt="Telone" class="img-fluid">
                 </figure>
                 <h5>Telone</h5>
                 <p class="mb-0">ADSL & Fiber Internet</p>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- feature-box -->

<!-- quality-system-section -->
<section>
  <div class="w-100 float-left quality-system-con">
     <div class="container">
        <div class="row">
           <div class="col-lg-6 col-md-6 d-flex align-items-center">
              <div class="quality-system-left-con">
                 <h2>Ditch the Lines, Pay your bills with Prepaid24!
                 </h2>
                 <p>Prepaid24.co.zw is a web based online bill payment and voucher platform, we offer a safe and easy way for Zimbabweans to buy airtime, data, electricity, pay school fees, including municipality or council bills.</p>
                 <p>Traditional bill payments can be time-consuming. Prepaid24 empowers you to take control of your of your bills.  Join the growing community who trust Prepaid24 for convenient and stress-free bill management.</p>
                 <div class="quality-system-list">
                    <ul class="list-unstyled">
                       <li class="position-relative"><i class="fas fa-circle"></i>Easily accessibility</li>
                       <li class="position-relative"><i class="fas fa-circle"></i>Secure and reliable</li>
                    </ul>
                 </div>
                 <div class="quality-system-list">
                    <ul class="list-unstyled">
                       <li class="position-relative"><i class="fas fa-circle"></i>24/7 Help Center</li>
                       <li class="position-relative"><i class="fas fa-circle"></i>Variety of products</li>
                    </ul>
                 </div>

              </div>
           </div>
           <div class="col-lg-6 col-md-6">
              <div class="quality-system-right-con position-relative text-center">
                 <figure class="mb-0">
                    <img src="assets/static/image/About-Prepaid24.jpg" alt="quality-system-img" class="img-fluid human-img">
                 </figure>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- quality-system-section -->

<!-- service-box -->
<section>
  <div class="w-100 float-left service-box-con">
     <div class="container">
 <h2 align="center">Our Services
                 </h2>
        <div class="service-box-inner-con">
           <div class="row ml-0 mr-0">
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Utilities-Prepaid24.png" alt="Utilities" class="img-fluid">
                    </figure>
                    <h5>Utilities</h5>
                    <span class="d-block">Pay your prepaid electricity purchases for Zesa and ZINWA water top-ups effortlessly via Prepaid24.</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Insurance.png" alt="Insurance" class="img-fluid">
                    </figure>
                    <h5>Insurance</h5>
                    <span class="d-block">Pay for car, home, and personal coverage from reputable providers such as First Mutual, Old Mutual, and numerous others.</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Government-Departments.png" alt="Government Departments" class="img-fluid">
                    </figure>
                    <h5>Government Departments</h5>
                    <span class="d-block">Pay your governmental dues for entities like ZIMRA and the Companies Registrar, among others, via Prepaid24.</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Gaming.png" alt="Gaming" class="img-fluid">
                    </figure>
                    <h5>Gaming </h5>
                    <span class="d-block">Hollywoodbets, Betway, Supabets, Blu Voucher and OTT top-up vouchers as well as Africa Bet, PowerBall, and Daily Lotto tickets and pay-outs are available.</span>
                 </div>
              </div>
           </div>
        </div>
  <div class="service-box-inner-con">
           <div class="row ml-0 mr-0">
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Travel.png" alt="Travel" class="img-fluid">
                    </figure>
                    <h5>Travel</h5>
                    <span class="d-block">Arrange your travel plans seamlessly by booking tickets for various carriers such as Mzansi, Sable class, Bravo, and more, via Prepaid24.</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Government-Departments.png" alt="Local Authorities" class="img-fluid">
                    </figure>
                    <h5>Local Authorities </h5>
                    <span class="d-block">Efficiently manage and pay municipal and city council bills, including rates and related expenses, via Prepaid24</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Tuition-Fees.png" alt="Tuition Fees" class="img-fluid">
                    </figure>
                    <h5>Tuition Fees</h5>
                    <span class="d-block">Simplify the process of tuition fee payments for universities and schools via Prepaid24</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Airtime.png" alt="Airtime" class="img-fluid">
                    </figure>
                    <h5>Airtime & Bundles</h5>
                    <span class="d-block">Enjoy uninterrupted communication with instant airtime top-up pinless vouchers and mobile data bundles for Econet, Netone, and Telecel and more via Prepaid24</span>
                 </div>
              </div>
           </div>
        </div>
        <div class="service-box-img">
           <figure class="mb-0">
              <img src="assets/static/image/Book-Bus.png" alt="Book Bus Tickets" class="img-fluid">
           </figure>
        </div>
        <div class="service-box-inner-con">
           <div class="row ml-0 mr-0">
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Broadband.png" alt="Broadband" class="img-fluid">
                    </figure>
                    <h5>Broadband</h5>
                    <span class="d-block">Stay connected with our broadband top-up services, covering providers like ZOL, Telone, and more, enjoy seamless connectivity via Prepaid24.</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Money-Transfers.png" alt="Money Transfers" class="img-fluid">
                    </figure>
                    <h5>Money Transfers</h5>
                    <span class="d-block">Seamlessly send and receive money via trusted services like Mukuru, SasaiRemit, Sikhona Money Transfers, MamaMoney, and HelloPaisa via Prepaid24</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0 border-right-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Entertainment.png" alt="Entertainment" class="img-fluid">
                    </figure>
                    <h5>Entertainment</h5>
                    <span class="d-block">Enjoy a wide range of entertainment gift vouchers for popular platforms such as Netflix, PlayStation, Google Plus, Amazon Video, Disney, and so much more.</span>
                 </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-6 pl-0 pr-0">
                 <div class="service-box-item text-center">
                    <figure>
                       <img src="assets/static/image/Global-Bills.png" alt="Global Bills" class="img-fluid">
                    </figure>
                    <h5>Global Bills</h5>
                    <span class="d-block">Effortlessly manage and settle bills across various countries including South Africa, Zambia, Malawi, Nigeria, and more, via Prepaid24.</span>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- service-box -->

<!-- news-letter-section -->
<section>
  <div class="w-100 float-left news-letter-con">
     <div class="container">
        <div class="news-letter-inner-con banner-overlay-img">
           <div class="row overlay-img align-items-center">
              <div class="col-lg-6 col-md-5">
                 <div class="news-letter-title">
                    <h2 class="text-white mb-0">Subscribe to our
                       Newsletter
                    </h2>
                 </div>
              </div>
              <div class="col-lg-6 col-md-7">
                 <div class="news-letter-input-con position-relative">
                    <div class="news-letter-input-feild">
                       <input type="email" placeholder="Enter Your Email:" class="w-100">
                    </div>
                    <button>
                    <i class="far fa-envelope"></i>
                    </button>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- news-letter-section -->
<!-- logo-section -->
<div class="w-100 float-left logo-con">
  <div class="container">
     <div class="logo-inner-con">
        <div class="row">
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">
                 <p>WE ACCEPT THE FOLLOWING</p>
              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">
                 <img src="assets/static/image/Ecocash.png" alt="Ecocash" class="img-fluid">
              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">
                 <img src="assets/static/image/Innbucks.png" alt="InnBucks" class="img-fluid">
              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">
                 <img src="assets/static/image/MasterCard.png" alt="Mastercard" class="img-fluid mb-0">
              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">
                 <img src="assets/static/image/Visa.png" alt="Visa" class="img-fluid mb-0">
              </figure>
           </div>
           <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <figure class="mb-0">
                 <img src="assets/static/image/ZimSwitch.png" alt="ZimSwitch" class="img-fluid mb-0">
              </figure>
           </div>
        </div>
     </div>
  </div>
</div>
<!-- logo-section -->
<!-- weight-footer-section -->
<section>
  <div class="w-100 float-left weight-footer-con">
     <div class="container">
        <div class="weight-footer-inner-con">
           <div class="row">
              <div class="col-lg-5 col-12 text-xl-left text-lg-left text-center">
                 <div class="weight-footer-content ">
                    <figure>
                       <img src="assets/static/image/Prepaid24-Logo.png" alt="Prepaid24 Zimbabwe" class="img-fluid">
                    </figure>
                    <p class="col-lg-11 col-md-7 pl-0 pr-0 ml-lg-0 mr-lg-0 ml-md-auto mr-md-auto">Online Bill Payments, Buy ZESA tokens, Pay School & University fees, Book bus tickets, Get Airtime Top-up, Buy lotto vouchers, and so much more.</p>
                    <div class="weight-social-list">
                       <ul class="list-unstyled mb-0">
                          <li class="d-inline-block"><a href="https://www.facebook.com/"><i class="fab fa-facebook-square d-flex align-items-center justify-content-center text-white pr-0"></i></a></li>
                          <li class="d-inline-block"><a href="https://twitter.com/?lang=en"><i class="fab fa-twitter-square d-flex align-items-center justify-content-center text-white pr-0"></i></a></li>
                          <li class="d-inline-block"><a href="https://www.linkedin.com/signup"><i class="fab fa-linkedin d-flex align-items-center justify-content-center text-white pr-0"></i></a></li>
                          <li class="d-inline-block"><a href="https://www.pinterest.com/"><i class="fab fa-pinterest-square mr-0 d-flex align-items-center justify-content-center text-white pr-0"></i></a></li>
                       </ul>
                    </div>
                 </div>
              </div>
              <div class="col-lg-2 col-md-4 col-sm-4 col-6">
                 <div class="weight-footer-content">
                    <h5>Important Link</h5>
                    <ul class="list-unstyled mb-0">
                       <li><i class="fas fa-caret-right"></i><a class="flink" routerLink="/about-us">About Us</a></li>
                       <li><i class="fas fa-caret-right"></i><a routerLink="/terms">Terms &amp; Conditions</a></li>
                       <li><i class="fas fa-caret-right"></i><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                 </div>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-3 col-6">
                 <div class="weight-footer-content">
                    <h5>Support</h5>
                    <ul class="list-unstyled mb-0">
                       <li><i class="fas fa-caret-right"></i>Support</li>
                       <li><i class="fas fa-caret-right"></i>Contact Us</li>
                       <li><i class="fas fa-caret-right"></i>Faq</li>
                    </ul>
                 </div>
              </div>
              <div class="col-lg-3 col-md-5 col-sm-5 col-12">
                 <div class="weight-footer-content">
                    <h5>Get in Touch</h5>
                    <ul class="list-unstyled mb-0">
                       <li><span>Email: </span>info&#64;prepaid24.co.zw</li>
                       <li><span>Phone: </span>+263 778 270 270</li>
                       <li><span>WhatsApp: </span>+263 777 007 007</li>
                    </ul>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!-- weight-footer-section -->
<!-- footer-section -->
<div class="w-100 float-left footer-con text-xl-left text-lg-left text-center">
  <div class="container">
     <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
           <p class="mb-0">Copyright ©2024 Prepaid24.co.zw All Rights Reserved</p>
        </div>
        <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center">
           <figure class="mb-0 d-inline-block">
              <img src="assets/static/image/payment-img1.png" alt="payment-img" class="img-fluid">
           </figure>
           <figure class="mb-0 d-inline-block">
              <img src="assets/static/image/payment-img2.png" alt="payment-img" class="img-fluid">
           </figure>
           <figure class="mb-0 d-inline-block">
              <img src="assets/static/image/payment-img3.png" alt="payment-img" class="img-fluid">
           </figure>
           <figure class="mb-0 d-inline-block mr-0">
              <img src="assets/static/image/payment-img4.png" alt="payment-img" class="img-fluid">
           </figure>
        </div>
     </div>
  </div>
</div>
<!-- footer-section -->
