import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomerDetails } from '../models/bill-payments/customers/customer-details';

@Injectable({
  providedIn: 'root',
})
export class BillPaymentsService {

  private baseUrl: string = environment.urls.api;

  public customerDetails = new BehaviorSubject<CustomerDetails | null>(null);

  public resetForms = new BehaviorSubject<boolean>(false)

  constructor(private http: HttpClient) {}

  public getCustomerDetails(supplier: string, accountNumber: string) {
    return this.http.get<CustomerDetails>(`${this.baseUrl}${supplier}/customer/view/${accountNumber}`)
    .pipe(
      map((result) => {
        this.customerDetails.next(result);
        return result;
      })
    );
  }
}
