import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { BillPaymentsService } from '../../services/bill-payments.service';
import { Error } from '../../models/common/i-error';
import { CustomerDetails } from '../../models/bill-payments/customers/customer-details';
import { InvoicesService } from '../../services/invoices.service';
import { CreateInvoice } from '../../models/bill-payments/invoices/create-invoice';
import { InvoiceCreated } from '../../models/bill-payments/invoices/invoice-created';
import { LoaderService } from '../../services/loader.service';
import { Product } from '../../models/bill-payments/products/product';
import { Package } from '../../models/bill-payments/products/package';
import { Addon } from '../../models/bill-payments/products/addon';

@Component({
  selector: 'app-bill-payment-workflow',
  templateUrl: './bill-payment-workflow.component.html',
  styleUrl: './bill-payment-workflow.component.scss',

})
export class BillPaymentWorkflowComponent implements OnChanges {
  accountDetails = this._formBuilder.group({
    accountNumber: ['', Validators.required],
    x: ['', Validators.required],
  });

  customerDetails = this._formBuilder.group({
    confirmed: [false, Validators.required],
    x: ['', Validators.required],
  });

  packageDetails = this._formBuilder.group({
    package: ['', Validators.required],
    x: ['', Validators.required],
  });

  addonDetails = this._formBuilder.group({
    addons: [''],
    x: ['', Validators.required],
  });

  amount = this._formBuilder.group({
    amount: [0, Validators.required],
    quantity: [1, Validators.required],
    x: ['', Validators.required],
  });

  contactDetails = this._formBuilder.group({
    phoneNumber: ['', Validators.required],
    email: [null, Validators.required],
    x: ['', Validators.required],
  });

  account!: string;

  customer!: CustomerDetails | null;

  details: string[] = [];

  purchase = 0;

  totalPurchase = 0;

  quantity = 0;

  accountNumber!: string;

  phoneNumber!: string;

  emailAddress!: string | null;

  isLoading = false;

  checked = false;

  packageSelected = false;

  @Input('product') product!: Product;

  selectedAddons: Addon[] = [];

  addons = '';

  selectedPackage!: Package | null;

  constructor(
    private _formBuilder: FormBuilder,
    private billPaymentsService: BillPaymentsService,
    private invoiceService: InvoicesService,
  ) {
    this.billPaymentsService.resetForms.asObservable().subscribe((reset) => {
      if (reset) {
        this.resetStates();

      }
    })
  }

  ngOnInit(): void {

  }

  resetStates() {
    this.accountDetails.reset();
    this.customerDetails.reset();
    this.amount.reset();
    this.contactDetails.reset();
    this.packageDetails.reset();
    this.addonDetails.reset();

    this.checked = false;
    this.purchase = 0;
    this.totalPurchase = 0;
    this.customer = null;
    this.selectedAddons = [];
    this.details = [];
    this.selectedPackage = null;
    this.addons = '';
    this.isLoading = false;
    this.packageSelected = false;
  }

  isSelected(): boolean {
    const c = this.packageSelected ? true : false;
    console.log('Is selected', c);
    return c;
  }

  activateAddons(addon: Addon) {
    console.log('Yeyi');

    const isSelected = this.arrayIncludesObject(this.selectedAddons, addon);

    if (isSelected) {
      console.log('removed', addon);
      const index = this.selectedAddons.indexOf(addon);
      this.selectedAddons.splice(index, 1);
      this.purchase -=  addon.productAddonsCost;
      console.log('updated price', this.purchase);

    } else {
      console.log('inserted', addon);

      this.selectedAddons.push(addon);
      this.purchase += addon.productAddonsCost;

      console.log('updated price', this.purchase);
    }
  }

  arrayIncludesObject(array: any[], objectToFind: any): boolean {
    return array.some((item: any) => {
      if (typeof item === 'object' && typeof objectToFind === 'object') {
        return JSON.stringify(item) === JSON.stringify(objectToFind);
      } else {
        return item === objectToFind;
      }
    });
  }

  selectPackage(p: Package) {
    this.selectedPackage = p;
  }

  sortPackages() {
    if (this.product.productPackage) {
      this.product.productPackage = this.product.productPackage.sort((a,b) => {
        return compare(
          a.productPackageDescription.toUpperCase(),
          b.productPackageDescription.toUpperCase(),
          true
        );
      })
    }
  }

  sortAddons() {
    if (this.product.productAddons) {
      this.product.productAddons = this.product.productAddons.sort((a,b) => {
        return compare(
          a.productAddonsDescription.toUpperCase(),
          b.productAddonsDescription.toUpperCase(),
          true
        );
      })
    }
  }

  confirm() {
    this.checked = !this.checked;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product']) {

      console.log('Selected product', this.product)

      if(this.product) {

        this.sortAddons();

        this.sortPackages();

        this.amount.patchValue({
          amount: this.product.price / 100
        })

        switch (this.product.supplierSlug) {
          case 'zetdc':
            this.account = 'Meter';
            break;
          case 'dstv':
            this.account = 'Account';
            break;
          default:
            this.account = 'Account';
            break;
        }

      }
    }

  }

  get accountDetailsForm() {
    return this.accountDetails.controls;
  }

  get confirmDetailForm() {
    return this.customerDetails.controls;
  }

  get packageDetailsForm() {
    return this.packageDetails.controls;
  }

  get addonDetailsForm() {
    return this.addonDetails.controls;
  }

  get amountForm() {
    return this.amount.controls;
  }

  get contactDetailsForm() {
    return this.contactDetails.controls;
  }

  getCustomerDetails(stepper: MatStepper) {

    this.isLoading = true;

    this.accountNumber = this.accountDetailsForm['accountNumber'].value!;

    this.billPaymentsService
      .getCustomerDetails(this.product.supplierSlug, this.accountNumber).subscribe({
        next: (customer) => {
          console.log('result here', customer);
          this.customer = customer;
          let c = customer.customerDetails;
          this.details = c.split('|');

          this.isLoading = false;

          if (stepper !== undefined) {
            (this.accountDetails as unknown as FormGroup<{ [key: string]: AbstractControl<any, any>; }>)
              .removeControl('x');
            stepper.next();
          }
        },
        error: (e: Error) => {
          window.alert('Error ' + e.error.code + ': ' + e.error.message);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  confirmDetail (stepper: MatStepper) {

    if (stepper !== undefined) {
      (this.customerDetails as unknown as FormGroup<{ [key: string]: AbstractControl<any, any>; }>)
        .removeControl('x');
      stepper.next();
    }

  }

  confirmPackage (stepper: MatStepper) {

    if(this.product.productPackage) {

      const p = parseInt(this.packageDetailsForm['package'].value!);

      console.log('Selected id', p);

      const s = findPackageByProductId(this.product.productPackage, p);

      this.selectedPackage = s as Package;

      console.log('Selected package', this.selectedPackage);

      this.packageSelected = true;

    }

    this.purchase += this.selectedPackage?.productPackageCost!;

    console.log('Price so far', this.purchase);

    if (stepper !== undefined) {
      (this.packageDetails as unknown as FormGroup<{ [key: string]: AbstractControl<any, any>; }>)
        .removeControl('x');
      stepper.next();
    }

  }

  confirmAddons (stepper: MatStepper) {

    this.addons = '';

    for (let i=0; i<this.selectedAddons.length; i++) {

      if (i==0) {
        this.addons += this.selectedAddons[i].productAddonsId.toString();
      }

      else {
        this.addons += '|' + this.selectedAddons[i].productAddonsId.toString();
      }
    }

    console.log('the addons', this.addons);

    console.log('the price after addons', this.purchase);

    this.amount.patchValue({
      amount: this.purchase / 100
    })

    if (stepper !== undefined) {
      (this.addonDetails as unknown as FormGroup<{ [key: string]: AbstractControl<any, any>; }>)
        .removeControl('x');
      stepper.next();
    }

  }

  setAmount(stepper: MatStepper) {

    if(this.product.productPackage && this.product.productAddons) {
      this.quantity = this.amountForm['quantity'].value as number;

      this.totalPurchase = this.purchase * this.quantity;

      console.log('This is the final price', this.totalPurchase);
    }

    else {
      this.totalPurchase = this.amountForm['amount'].value as number * 100;
      this.purchase = this.product.price;
      this.quantity = this.amountForm['amount'].value as number;
    }

    if (stepper !== undefined) {
      (this.amount as unknown as FormGroup<{ [key: string]: AbstractControl<any, any>; }>)
        .removeControl('x');
      stepper.next();
    }

  }

  makePayment(stepper?: MatStepper) {

    this.isLoading = true;

    this.phoneNumber = this.contactDetailsForm['phoneNumber'].value as string;

    this.emailAddress = this.contactDetailsForm['email'].value;

    const invoice: CreateInvoice = {
      amount: this.totalPurchase,
      productId: this.product.productId,
      ownerId: this.customer?.serverRequestId.toString(),
      mobile: this.phoneNumber,
      email: this.emailAddress,
      paymentMethodId: 1,
      quantity: this.quantity,
      unitPrice: this.purchase,
      serverRequestId: this.customer?.serverRequestId,
      accountNumber: this.accountNumber
    }

    if (this.selectedPackage) {
      invoice.packageId = this.selectedPackage?.productPackageId;
      invoice.productAddons = this.addons
    }

    this.invoiceService.createInvoice(invoice).subscribe({
      next: (invoice) => {
        this.isLoading = false
        console.log('result here', invoice);
        window.open(invoice.redirectUrl, '_self');
      },
      error: (e: Error) => {
        console.log('Error', e);
        this.isLoading = false
        window.alert('Error ' + e.error.code + ': ' + e.error.message);
      },
      complete: () => {
        this.isLoading = false
      },
    })

  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function findPackageByProductId(packages: Package[], productId: number): Package | undefined {
  return packages.find((pack: Package) => pack.productPackageId === productId);
}
